import { useEffect } from "react";
import $ from "jquery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.min.js";

export default function StudentsReview() {

    useEffect(() => {
        const $slider = $(".slider1");
        $slider.slick({
          centerMode: true,
    
          autoplay: true,
          dots: true,
          infinite: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                arrows: false,
                centerMode: true,
    
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 600,
              settings: {
                arrows: false,
                centerMode: false,
    
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                arrows: false,
                centerMode: false,
                slidesToShow: 1,
              },
            },
          ],
        });
    
        return () => {
          if ($slider.hasClass("slick-initialized")) {
            $slider.slick("unslick");
          }
        };
      }, []);
    

    return (
        <>
         <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div className="max-w-3xl mx-auto mb-10 text-center lg:mb-14">
            <h2 className="text-3xl font-bold text-gray-800 md:text-4xl lg:text-5xl md:leading-tight">
              Students Feedback
            </h2>
            <p className="mt-1 text-lg text-gray-600 lg:text-xl">
              {" "}
              A Students Review for Kavin’s Academy
            </p>
          </div>
          <div className="slider1">
            <figure className="relative flex flex-col-reverse bg-[#EEEEEE]  p-6 border rounded-xl shadow-sm mx-2 slide-item transform scale-90 h-full">
              <figcaption className="flex items-center space-x-4">
                <span className="relative  inline-flex items-center justify-center h-[3.875rem] w-[3.875rem] rounded-full bg-gray-600">
                  <span className="text-lg font-medium leading-none text-white">
                    G
                  </span>
                </span>
                <div className="flex-auto">
                  <div className="text-base font-semibold text-slate-900 ">
                    <a href="#!" tabIndex="0">
                      <span className="absolute inset-0"></span>Ganesh
                    </a>
                  </div>
                  <div className="mt-0.5">Student</div>
                </div>
              </figcaption>
              <blockquote className="mt-6 text-slate-700 ">
                <p>
                  Incredible coaching, boosted my confidence, and helped me
                  excel in exams.
                </p>
              </blockquote>
            </figure>
            <figure className="relative flex flex-col-reverse bg-[#EEEEEE]  p-6 border rounded-xl shadow-sm mx-2 slide-item transform scale-90 h-full">
              <figcaption className="flex items-center space-x-4">
                <span className="inline-flex items-center justify-center h-[3.875rem] w-[3.875rem] rounded-full bg-gray-600">
                  <span className="text-lg font-medium leading-none text-white">
                    A
                  </span>
                </span>
                <div className="flex-auto">
                  <div className="text-base font-semibold text-slate-900 ">
                    <a href="#!" tabIndex="0">
                      <span className="absolute inset-0"></span>Awasthi
                    </a>
                  </div>
                  <div className="mt-0.5">Student</div>
                </div>
              </figcaption>
              <blockquote className="mt-6 text-slate-700 ">
                <p>
                  Top-notch instructors, comprehensive study materials - the
                  perfect recipe for success!
                </p>
              </blockquote>
            </figure>
            <figure className="relative flex flex-col-reverse bg-[#EEEEEE]  p-6 border rounded-xl shadow-sm mx-2 slide-item transform scale-90 h-full">
              <figcaption className="flex items-center space-x-4">
                <span className="inline-flex items-center justify-center h-[3.875rem] w-[3.875rem] rounded-full bg-gray-600">
                  <span className="text-lg font-medium leading-none text-white">
                    R
                  </span>
                </span>
                <div className="flex-auto">
                  <div className="text-base font-semibold text-slate-900 ">
                    <a href="#!" tabIndex="0">
                      <span className="absolute inset-0"></span>R. Gayathri
                    </a>
                  </div>
                  <div className="mt-0.5">Student</div>
                </div>
              </figcaption>
              <blockquote className="mt-6 text-slate-700 ">
                <p>
                  Life-changing experience! The coaching center turned my
                  weaknesses into strengths.
                </p>
              </blockquote>
            </figure>
            <figure className="relative flex flex-col-reverse bg-[#EEEEEE]  p-6 border rounded-xl shadow-sm mx-2 slide-item transform scale-90 h-full">
              <figcaption className="flex items-center space-x-4">
                <span className="inline-flex items-center justify-center h-[3.875rem] w-[3.875rem] rounded-full bg-gray-600">
                  <span className="text-lg font-medium leading-none text-white">
                    P
                  </span>
                </span>
                <div className="flex-auto">
                  <div className="text-base font-semibold text-slate-900 ">
                    <a href="#!" tabIndex="0">
                      <span className="absolute inset-0"></span>Priya
                    </a>
                  </div>
                  <div className="mt-0.5">Student</div>
                </div>
              </figcaption>
              <blockquote className="mt-6 text-slate-700 ">
                <p>
                  Exceptional support, motivating workshops, and personalized
                  guidance made all the difference.
                </p>
              </blockquote>
            </figure>
            <figure className="relative flex flex-col-reverse bg-[#EEEEEE]  p-6 border rounded-xl shadow-sm mx-2 slide-item transform scale-90 h-full">
              <figcaption className="flex items-center space-x-4">
                <span className="inline-flex items-center justify-center h-[3.875rem] w-[3.875rem] rounded-full bg-gray-600">
                  <span className="text-lg font-medium leading-none text-white">
                    R
                  </span>
                </span>
                <div className="flex-auto">
                  <div className="text-base font-semibold text-slate-900 ">
                    <a href="#!" tabIndex="0">
                      <span className="absolute inset-0"></span>Rahul
                    </a>
                  </div>
                  <div className="mt-0.5">Student</div>
                </div>
              </figcaption>
              <blockquote className="mt-6 text-slate-700 ">
                <p>
                  A game-changer! This coaching center&apos;s dedication to
                  excellence is unmatched.
                </p>
              </blockquote>
            </figure>
          </div>
        </div>
        </>
    )
}