export default function OurFaculties() {
    return (
        <>
         <div className="max-w-[110rem] w-full px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          {/* <!-- Title --> */}
          <div className="max-w-3xl mx-auto mb-10 text-center lg:mb-14">
            <h2 className="text-3xl font-bold text-gray-800 md:text-4xl lg:text-5xl md:leading-tight">
              Our Faculties
            </h2>
            <p className="mt-1 text-lg text-gray-600 lg:text-xl">
              Our faculty members have chiselled and crafted more than thousand
              student aspirants to realise their dream of becoming a civil
              servant.
            </p>
          </div>
          <div className="grid items-center grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 md:gap-10">
            <div className="relative z-0 flex flex-col w-full h-full overflow-hidden bg-white">
              <img
                className="w-full h-auto border shadow-sm rounded-t-xl rounded-xl"
                src="/images/ourteam/Valan-web.jpg"
                alt="Image Description"
              />
              <div className="max-w-[16rem] w-full h-full relative -top-7  lg:-top-10 flex flex-auto flex-col justify-center items-center p-3 bg-gray-800  rounded-2xl mx-auto shadow-sm">
                <div className="absolute inset-0 z-10 "></div>
                <div className="relative z-20 text-center">
                  <h3 className="mb-2 font-bold text-white">
                    Dr. VALAN SUBARAJA J A
                  </h3>
                </div>
              </div>
            </div>
            <div className="relative z-0 flex flex-col w-full h-full overflow-hidden bg-white">
              <img
                className="w-full h-auto border shadow-sm rounded-t-xl rounded-xl"
                src="/images/ourteam/rajesh-web.jpg"
                alt="Image Description"
              />
              <div className="max-w-[16rem] w-full h-full relative -top-7 lg:-top-10 flex flex-auto flex-col justify-center items-center p-3 bg-gray-800  rounded-2xl mx-auto shadow-sm">
                <div className="absolute inset-0 z-10 "></div>
                <div className="relative z-20 text-center">
                  <h3 className="mb-2 font-bold text-white ">
                    Mr. RAJESH ALAGUMANI
                  </h3>
                </div>
              </div>
            </div>
            <div className="relative z-0 flex flex-col w-full h-full overflow-hidden bg-white">
              <img
                className="w-full h-auto border shadow-sm rounded-t-xl rounded-xl"
                src="/images/ourteam/duraish-Web.jpg"
                alt="Image Description"
              />
              <div className="max-w-[16rem] w-full h-full relative -top-7 lg:-top-10 flex flex-auto flex-col justify-center items-center p-3  bg-gray-800  rounded-2xl mx-auto shadow-sm">
                <div className="absolute inset-0 z-10 "></div>
                <div className="relative z-20 text-center">
                  <h3 className="mb-2 font-bold text-white ">
                    Mr. M V DURAISH KUMAR
                  </h3>
                </div>
              </div>
            </div>
            <div className="relative z-0 flex flex-col w-full h-full overflow-hidden bg-white">
              <img
                className="w-full h-auto border shadow-sm rounded-t-xl rounded-xl"
                src="/images/ourteam/dinesh swamy prasanna - Web.jpg"
                alt="Image Description"
              />
              <div className="max-w-[16rem] w-full h-full relative -top-7 lg:-top-10 flex flex-auto flex-col justify-center items-center p-3  bg-gray-800  rounded-2xl mx-auto shadow-sm">
                <div className="absolute inset-0 z-10 "></div>
                <div className="relative z-20 text-center">
                  <h3 className="mb-2 font-bold text-white ">
                    Mr. DINESH SWAMY PRASANNA N
                  </h3>
                </div>
              </div>
            </div>
            <div className="relative z-0 flex flex-col w-full h-full overflow-hidden bg-white">
              <img
                className="w-full h-auto border shadow-sm rounded-t-xl rounded-xl"
                src="/images/ourteam/Venkat-Web.jpg"
                alt="Image Description"
              />
              <div className="max-w-[16rem] w-full h-full relative -top-7 lg:-top-10 flex flex-auto flex-col justify-center items-center p-3  bg-gray-800  rounded-2xl mx-auto shadow-sm">
                <div className="absolute inset-0 z-10 "></div>
                <div className="relative z-20 text-center">
                  <h3 className="mb-2 font-bold text-white ">Mr. VENKAT</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
    )
}