import { useEffect } from "react";
import $ from "jquery";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.min.js";

export default function SliderSection() {

    useEffect(() => {
        const $slider = $(".slider");
    
        $slider.slick({
          autoplay: true,
          infinite: true,
          slidesToShow: 4,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        });
    
        return () => {
          if ($slider.hasClass("slick-initialized")) {
            $slider.slick("unslick");
          }
        };
      }, []);

    return (
        <>
        <div className="max-w-[110rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                  <div className="slider">
                    <div className="min-h-[15rem] flex flex-col">
                      <div className="flex flex-col items-center justify-center flex-auto p-4 md:p-5">
                        <svg
                          className="max-w-[5rem]  text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 576 512"
                        >
                          <path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z" />
                        </svg>
                        <p className="text-2xl text-center text-gray-400">
                          Interactive PPTs & Video Lectures
                        </p>
                      </div>
                    </div>
                    <div className="min-h-[15rem] flex flex-col">
                      <div className="flex flex-col items-center justify-center flex-auto p-4 md:p-5">
                        <svg
                          className="max-w-[5rem]  text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 576 512"
                        >
                          <path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" />
                        </svg>
                        <p className="text-2xl text-center text-gray-400">
                          Experienced Faculties
                        </p>
                      </div>
                    </div>
                    <div className="min-h-[15rem] flex flex-col">
                      <div className="flex flex-col items-center justify-center flex-auto p-4 md:p-5">
                        <svg
                          className="max-w-[5rem]  text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 512 512"
                        >
                          <path d="M160 96a96 96 0 1 1 192 0A96 96 0 1 1 160 96zm80 152V512l-48.4-24.2c-20.9-10.4-43.5-17-66.8-19.3l-96-9.6C12.5 457.2 0 443.5 0 427V224c0-17.7 14.3-32 32-32H62.3c63.6 0 125.6 19.6 177.7 56zm32 264V248c52.1-36.4 114.1-56 177.7-56H480c17.7 0 32 14.3 32 32V427c0 16.4-12.5 30.2-28.8 31.8l-96 9.6c-23.2 2.3-45.9 8.9-66.8 19.3L272 512z" />
                        </svg>
                        <p className="text-2xl text-center text-gray-400">
                          Personalized & Effective Learning
                        </p>
                      </div>
                    </div>
                    <div className="min-h-[15rem] flex flex-col">
                      <div className="flex flex-col items-center justify-center flex-auto p-4 md:p-5">
                        <svg
                          className="max-w-[5rem]  text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 512 512"
                        >
                          <path d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z" />
                        </svg>
        
                        <p className="text-2xl text-center text-gray-400">
                          Instant feedback & Assessment
                        </p>
                      </div>
                    </div>
                    <div className="min-h-[15rem] flex flex-col">
                      <div className="flex flex-col items-center justify-center flex-auto p-4 md:p-5">
                        <svg
                          className="max-w-[5rem]  text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M192 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm-8 384V352h16V480c0 17.7 14.3 32 32 32s32-14.3 32-32V192h56 64 16c17.7 0 32-14.3 32-32s-14.3-32-32-32H384V64H576V256H384V224H320v48c0 26.5 21.5 48 48 48H592c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48H368c-26.5 0-48 21.5-48 48v80H243.1 177.1c-33.7 0-64.9 17.7-82.3 46.6l-58.3 97c-9.1 15.1-4.2 34.8 10.9 43.9s34.8 4.2 43.9-10.9L120 256.9V480c0 17.7 14.3 32 32 32s32-14.3 32-32z" />
                        </svg>
                        <p className="text-2xl text-center text-gray-400">
                          State-of-the-Art Classroom
                        </p>
                      </div>
                    </div>
                    <div className="min-h-[15rem] flex flex-col">
                      <div className="flex flex-col items-center justify-center flex-auto p-4 md:p-5">
                        <svg
                          className="max-w-[5rem]  text-gray-400"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M160 64c0-35.3 28.7-64 64-64H576c35.3 0 64 28.7 64 64V352c0 35.3-28.7 64-64 64H336.8c-11.8-25.5-29.9-47.5-52.4-64H384V320c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v32h64V64L224 64v49.1C205.2 102.2 183.3 96 160 96V64zm0 64a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM133.3 352h53.3C260.3 352 320 411.7 320 485.3c0 14.7-11.9 26.7-26.7 26.7H26.7C11.9 512 0 500.1 0 485.3C0 411.7 59.7 352 133.3 352z" />
                        </svg>
                        <p className="text-2xl text-center text-gray-400">
                          Flexible Online Classes
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
        </>
    )
}