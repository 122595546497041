export default function CoursesOffered () {

    return(
        <>
         <div className="max-w-[85rem] w-full px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          {/* <!-- Title --> */}
          <div className="max-w-3xl mx-auto mb-10 text-center lg:mb-14">
            <h2 className="text-3xl font-bold text-gray-800 md:text-4xl lg:text-5xl md:leading-tight">
              Courses Offered
            </h2>
          </div>
          <div className="grid items-center gap-6 text-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 md:gap-6">
            <div className="flex flex-col transition bg-white border shadow-lg rounded-xl ">
              <img
                className="w-full h-auto rounded-t-xl"
                src="/images/courses/UPSC 1.jpg"
                alt="Image Description"
              />
              <div className="p-4 md:p-5">
                <h3 className="text-xl font-bold text-gray-800 lg:text-3xl ">
                  UPSC
                </h3>
                <a
                  className="inline-flex items-center justify-center px-6 py-3 mt-3 text-lg font-medium leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue"
                  href="#joinnow"
                >
                  Join Now
                </a>
              </div>
            </div>
            <div className="flex flex-col transition bg-white border shadow-lg rounded-xl ">
              <img
                className="w-full h-auto rounded-t-xl"
                src="/images/courses/TNPSC1.jpg"
                alt="Image Description"
              />
              <div className="p-4 md:p-5">
                <h3 className="text-xl font-bold text-gray-800 lg:text-3xl ">
                  TNPSC
                </h3>
                <a
                  className="inline-flex items-center justify-center px-6 py-3 mt-3 text-lg font-medium leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue"
                  href="#joinnow"
                >
                  Join Now
                </a>
              </div>
            </div>
            <div className="flex flex-col transition bg-white border shadow-lg rounded-xl ">
              <img
                className="w-full h-auto rounded-t-xl"
                src="/images/courses/BANK1.jpg"
                alt="Image Description"
              />
              <div className="p-4 md:p-5">
                <h3 className="text-xl font-bold text-gray-800 lg:text-3xl ">
                  IBPS
                </h3>
                <a
                  className="inline-flex items-center justify-center px-6 py-3 mt-3 text-lg font-medium leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue"
                  href="#joinnow"
                >
                  Join Now
                </a>
              </div>
            </div>
            <div className="flex flex-col transition bg-white border shadow-lg rounded-xl ">
              <img
                className="w-full h-auto rounded-t-xl"
                src="/images/courses/TET1.jpg"
                alt="Image Description"
              />
              <div className="p-4 md:p-5">
                <h3 className="text-xl font-bold text-gray-800 lg:text-3xl ">
                  TET-TRB
                </h3>
                <a
                  className="inline-flex items-center justify-center px-6 py-3 mt-3 text-lg font-medium leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue"
                  href="#joinnow"
                >
                  Join Now
                </a>
              </div>
            </div>
            <div className="flex flex-col transition bg-white border shadow-lg rounded-xl ">
              <img
                className="w-full h-auto rounded-t-xl"
                src="/images/courses/SRB-DRB.jpg"
                alt="Image Description"
              />
              <div className="p-4 md:p-5">
                <h3 className="text-xl font-bold text-gray-800 lg:text-3xl ">
                  SRB/DRB
                </h3>
                <a
                  className="inline-flex items-center justify-center px-6 py-3 mt-3 text-lg font-medium leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue"
                  href="#joinnow"
                >
                  Join Now
                </a>
              </div>
            </div>
            <div className="flex flex-col transition bg-white border shadow-lg rounded-xl ">
              <img
                className="w-full h-auto rounded-t-xl"
                src="/images/courses/per.jpg"
                alt="Image Description"
              />
              <div className="p-4 md:p-5">
                <h3 className="text-xl font-bold text-gray-800 lg:text-3xl ">
                  PD & Soft Skills
                </h3>
                <a
                  className="inline-flex items-center justify-center px-6 py-3 mt-3 text-lg font-medium leading-6 text-center text-white transition-all duration-300 ease-in-out rounded-lg my-link gap-x-3 bg-regal-blue hover:bg-gradient-to-l hover:from-regal-yellow hover:to-regal-blue"
                  href="#joinnow"
                >
                  Join Now
                </a>
              </div>
            </div>
          </div>
        </div>
        </>
    )
}