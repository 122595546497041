import { useEffect, useRef } from "react";
import toast from "react-hot-toast";

export default function Welcome() {

     // --------------------- quick enquiry form ---------------- //
      const scriptURL1 = 'https://script.google.com/macros/s/AKfycby4d4FhRODgSSXZ-lagcNjGeJXApcWD5QbmbAY3ZFl2VilC72YfdFINY3BRGfkHOWfY-Q/exec';
      const formRef = useRef(null);
      const loadingIndicatorRef = useRef(null);
    
      useEffect(() => {
          const form = formRef.current;
          const loadingIndicator = loadingIndicatorRef.current;
    
          const handleSubmit = (e) => {
              e.preventDefault();
              loadingIndicator.style.display = 'block';
              fetch(scriptURL1, { method: 'POST', body: new FormData(form) })
              .then(response => response.json())
              .then(data => {
                  console.log('Full response:', data);
                  loadingIndicator.style.display = 'none';
                  toast.success("Thank you! your form is submitted successfully.");
                  form.reset();
              })
              .catch(error => {
                  console.error('Error!', error);
                  loadingIndicator.style.display = 'none';
              });
          };
    
          form.addEventListener('submit', handleSubmit);
    
          return () => {
              form.removeEventListener('submit', handleSubmit);
          };
      }, []);

    return (
        <>
               <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-12 lg:gap-x-8 xl:gap-x-12 lg:items-center">
            <div className="text-center lg:col-span-8 lg:text-start">
              <div className="flex justify-center mb-5 lg:justify-start">
                <a className="inline-flex items-center gap-x-2 bg-white border border-gray-200 lg:text-sm text-[10px] text-gray-800 p-1 pl-3 rounded-full transition hover:border-gray-300 ">
                  Elevate Your Potential: Ignite Success with Us!
                  <span className="inline-flex items-center justify-center px-3 py-2 text-sm font-semibold text-white rounded-full gap-x-2 bg-regal-yellow ">
                    <svg className="w-2.5 h-2.5" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
                    </svg>
                  </span>
                </a>
              </div>
              <h1 className="block text-4xl font-bold text-gray-800 sm:text-5xl md:text-6xl lg:text-[81px] font-sans">
                Welcome to{" "}
                <span className="underline decoration-[8px] decoration-regal-yellow">
                  Kavin’s Academy
                </span>
              </h1>
              <p className="mt-6 text-lg text-gray-600 lg:text-xl ">
                An Ultimate Destination fo UPSC, TNPSC, IBPS, TET-TRB, SRD/DRB
                (Coop) Aspirants
              </p>
              <a className="mt-6 inline-flex items-center   lg:gap-x-2 font-semibold  bg-regal-yellow  border border-gray-200 lg:text-sm text-[12px] text-gray-800 p-2 pl-3 rounded-full transition hover:border-gray-300 ">
                <svg className="text-regal-blue" xmlns="http://www.w3.org/2000/svg" height="2em" fill="currentColor" viewBox="0 0 640 512" >
                  {" "}
                  <path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" />
                </svg>
                BOTH ONLINE AND OFFLINE CLASSES AVAILABLE
              </a>
            </div>

            <div className="mt-10 lg:mt-0 lg:col-span-4">
              <div className="flex flex-col transition bg-white border shadow-sm group rounded-xl hover:shadow-md " href="#" >
                <div className="p-4 my-5 md:p-5">
                  <h1 className="mb-8 font-sans text-3xl font-bold text-center text-gray-800 md:text-3xl lg:text-4xl">
                    Quick <span className="text-regal-yellow">Enquiry</span>
                  </h1>

                  <form id="quickenquiryform" className="space-y-4 md:space-y-6" ref={formRef} action="YOUR_GOOGLE_APPS_SCRIPT_URL" method="post" >
                    <div>
                      <label htmlFor="Name" className="block mb-2 text-sm font-medium text-gray-900 "> Your Name </label>
                      <input type="text" id="Name" name="Name"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 "
                        required=""
                      />
                    </div>
                    <div>
                      <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 " >Your Mobile Number </label>
                      <input type="text" id="Mobile Number" name="Mobile Number"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                        required=""
                      />
                    </div>
                    <button type="submit"
                      className="w-full text-white bg-regal-blue hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                      Submit
                    </button>
                  </form>
                  <div className="loading2" ref={loadingIndicatorRef} style={{ display: 'none' }}>
                        <img src="/images/timer.gif" alt="Loading..." />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
    )
}