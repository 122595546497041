import { useEffect, useRef } from "react";
import toast from "react-hot-toast";

export default function JoinNow() {

     // --------------------- join now form ---------------- //
    
      const scriptURL2 = 'https://script.google.com/macros/s/AKfycby4d4FhRODgSSXZ-lagcNjGeJXApcWD5QbmbAY3ZFl2VilC72YfdFINY3BRGfkHOWfY-Q/exec';
      const formRef1 = useRef(null);
      const loadingIndicatorRef1 = useRef(null);
    
      useEffect(() => {
          const form = formRef1.current;
          const loadingIndicator1 = loadingIndicatorRef1.current;
    
          const handleSubmit = (e) => {
              e.preventDefault();
              loadingIndicator1.style.display = 'block';
              fetch(scriptURL2, { method: 'POST', body: new FormData(form) })
                  .then(() => {
                      loadingIndicator1.style.display = 'none';
                      toast.success("Thank you! your form is submitted successfully.");
                      form.reset();
                  })
                  .catch(error => {
                      console.error('Error!', error.message);
                      loadingIndicator1.style.display = 'none';
                  });
          };
    
          form.addEventListener('submit', handleSubmit);
    
          return () => {
              form.removeEventListener('submit', handleSubmit);
          };
      }, []);

    return (
        <>
          <div className="max-w-[85rem] overflow-hidden w-full h-full py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div className="grid items-center grid-cols-1 gap-6 md:gap-10">
            <div className="bg-[url('/images/news-bg.png')] bg-center bg-no-repeat rounded-2xl flex justify-center items-center px-4 py-20 sm:px-6 lg:px-8 lg:py-14">
              <div className="flex flex-col shadow-slate-900/[.7] bg-slate-900/[.7] shadow-sm rounded-xl max-w-full lg:max-w-[40rem] lg:max-h-[40rem] w-full h-full">
                <div className="p-5 text-center md:p-10">
                  <div className="hidden mb-5">
                    <img src="./assets/images/logo/favicon.png" alt="" className="w-auto h-10 lg:h-16" />
                  </div>
                  <h3 className="text-3xl font-extrabold lg:text-5xl text-regal-yellow"> Join Now </h3>
                  <form className="mt-5" id="joinnowform" ref={formRef1} method="post" action="YOUR_GOOGLE_APPS_SCRIPT_URL1">
                    <div className="grid grid-flow-row-dense grid-cols-4 gap-4">
                      <div className="col-span-full lg:col-span-2">
                        <label htmlFor="fullname" className="block mb-2 text-sm text-white text-start" >Full Name</label>
                        <div className="relative">
                          <input type="text" id="fullname" name="Name"
                            className="block w-full px-4 py-3 text-sm border-gray-200 rounded-md pl-11 focus:border-regal-yellow focus:ring-regal-yellow"
                            required aria-describedby="fullname-error"
                          />
                          <div className="absolute inset-y-0 left-0 z-20 flex items-center pl-4 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 448 512" >
                              <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-full lg:col-span-2">
                        <label htmlFor="email" className="block mb-2 text-sm text-white text-start" >Email address</label>
                        <div className="relative">
                          <input type="email" id="email" name="Email"
                            className="block w-full px-4 py-3 text-sm border-gray-200 rounded-md pl-11 focus:border-regal-yellow focus:ring-regal-yellow"
                            required aria-describedby="email-error"
                          />
                          <div className="absolute inset-y-0 left-0 z-20 flex items-center pl-4 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16" >
                              <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-full">
                        <label htmlFor="mobile" className="block mb-2 text-sm text-white text-start" > Mobile Number</label>
                        <div className="relative">
                          <input type="text" id="mobile" name="Mobile Number" className="block w-full px-4 py-3 text-sm border-gray-200 rounded-md pl-11 focus:border-regal-yellow focus:ring-regal-yellow" required aria-describedby="mobile-error"
                          />
                          <div className="absolute inset-y-0 left-0 z-20 flex items-center pl-4 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 384 512">
                              <path d="M80 0C44.7 0 16 28.7 16 64V448c0 35.3 28.7 64 64 64H304c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H80zm80 432h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H160c-8.8 0-16-7.2-16-16s7.2-16 16-16z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-full">
                        <label htmlFor="course" className="block mb-2 text-sm text-white text-start">Select a Course</label>
                        <div className="relative">
                          <select id="course" name="Course Name"
                            className="block w-full px-4 py-3 text-sm border-gray-200 rounded-md pl-11 focus:border-regal-yellow focus:ring-regal-yellow"
                            required
                          >
                            <option value="" selected></option>
                            <option value="UPSC">UPSC</option>
                            <option value="TNPSC">TNPSC</option>
                            <option value="IBPS">IBPS</option>
                            <option value="TET-TRB">TET-TRB</option>
                            <option value="SRB/DRB">SRB/DRB</option>
                            <option value="PD & Soft Skills">
                              PD & Soft Skills
                            </option>
                          </select>
                          <div className="absolute inset-y-0 left-0 z-20 flex items-center pl-4 pointer-events-none">
                            <svg className="w-4 h-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 640 512" >
                              <path d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l57.9 20.9C57.3 229.3 48 259.8 48 291.9v28.1c0 28.4-10.8 57.7-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7C90.3 344.3 86 329.8 80 316.5V291.9c0-30.2 10.2-58.7 27.9-81.5c12.9-15.5 29.6-28 49.2-35.7l157-61.7c8.2-3.2 17.5 .8 20.7 9s-.8 17.5-9 20.7l-157 61.7c-12.4 4.9-23.3 12.4-32.2 21.6l159.6 57.6c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-full">
                        <label htmlFor="message" className="block mb-2 text-sm text-white text-start">Message</label>
                        <div className="relative">
                          <textarea id="message" name="Message"
                            className="block w-full px-4 py-3 text-sm border-gray-200 rounded-md focus:border-blue-500 focus:ring-blue-500"
                            required aria-describedby="message-error"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-span-full">
                        <div className="relative">
                          <button type="submit"
                            className="inline-flex items-center justify-center w-full gap-2 px-4 py-3 text-sm font-semibold text-white transition-all border border-transparent rounded-md bg-regal-yellow hover:bg-regal-blue focus:outline-none focus:ring-2 focus:bg-regal-blue focus:ring-offset-2"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  <div className="loading2" ref={loadingIndicatorRef1} style={{ display: 'none' }}>
                        <img src="/images/timer.gif" alt="Loading..." />
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
    )
}